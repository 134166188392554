




























import { Vue, Component, Prop } from 'vue-property-decorator';
import moment from 'moment';

import HistoricService from '@/services/Historic/historicService';
import ExerciseService from '@/services/Exercise/ExerciseService';

import Icons from './Icons/Icons.vue';

import ActiveModal from '@/share/Util/ActiveModal';

@Component({
  components: {
    Icons
  }
})
export default class IconStatus extends Vue {
  @Prop() type!: string;
  @Prop() duration!: number;
  @Prop() progress!: number;
  @Prop({ default: false }) isPermission!: boolean;
  @Prop() data!: Record<string, any>;
  @Prop() topic!: Record<string, any>;
  @Prop() material!: Record<string, any>;

  private setModal = new ActiveModal();

  private historicService = new HistoricService();
  private exerciseService = new ExerciseService();

  setTime() {
    const formatted = moment.utc(this.duration * 1000).format('HH:mm:ss');
    return formatted;
  }

  async handleClick() {
    if (!this.isPermission) {
      this.setModalPermission();
      return;
    }

    await this.changePath();
  }

  setModalPermission() {
    this.setModal.activeModal('modalNotContentPlan');
  }

  async changePath() {
    if (this.type === 'material') {
      await this.setDownloadArchive();
      return;
    }

    this.saveDataInStore(this.topic, this.data);

    if (this.type === 'exercise') {
      await this.redirectExerciseResult();
      return;
    }

    const path = `${this.data.to}/${this.data.from}/${this.topic.idTopic}/${this.data.id}/${this.data.file.slug}`;

    this.$router.push({
      path
    });
  }

  async setDownloadArchive() {
    this.$emit('checkedMaterials', this.data.id);
    this.openArchiveNewWindow();
    await this.setCheckbox('materiais', false);
  }

  saveDataInStore(topic: Record<string, any>, data: Record<string, any>) {
    this.$store.commit('setTopic', topic);
    this.$store.commit('setFile', data.file);
  }

  async redirectExerciseResult() {
    try {
      const response = await this.exerciseService.getExerciseByUser(Number(this.data.id));

      if (response) {
        const path = `${this.data.to}/${this.data.from}/${this.topic.idTopic}/${this.data.id}/${this.data.file.slug}/resultado`;

        this.$router.push({
          path
        });
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar a resposta do exercício.',
        status: 'error'
      });

      console.error(error);
    }
  }

  async setCheckbox(type: string, checked: boolean) {
    await this.historicService.saveHistoric({
      idobject: this.data.id,
      type,
      checked: !checked
    });
  }

  openArchiveNewWindow() {
    const a = document.createElement('a') as HTMLAnchorElement;

    a.href = `https://explicae.com.br/app/${this.material.url}`;

    a.download = this.material.name;

    a.target = '_blank';

    (document.body || document.documentElement).appendChild(a);
    a.click();
    // @ts-ignore
    a.parentNode.removeChild(a);
  }
}
